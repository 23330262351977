$(function(){

  // Language switch

  var $langSwitch = $(".language-switch");
  var $langList = $(".language-list");

  $langSwitch.click(function() {
    $langList.slideToggle();
  });

});
